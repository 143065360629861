<template>
  <div>
    <!-- 面包屑区域 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card shadow="hover">
      <Title :title="title[0]"></Title>

      <div class="recharge_box">
        <div class="external_box">
          <div class="line"></div>
          <div class="content">
            <div class="balance">
              当前可用余额：
              <span>￥{{userInfo.money}}</span> 元
            </div>

            <div class="timeline_box">
              <el-timeline :reverse="reverse">
                <!-- <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.timestamp">{{activity.content}}</el-timeline-item> -->
                   <el-timeline-item timestamp="支付宝账户" placement="top">
                       <el-button type="primary" class="haowu hw-add add_bindingAlipay" @click="addBindingAlipay" v-if="!userInfo.alimot">&nbsp;&nbsp;绑定支付宝账户</el-button>
                       <span class="alipaytext">{{userInfo.alimot}}</span>
                   </el-timeline-item>
                   <el-timeline-item timestamp="提交提现信息（转账至平台指定账号后，点击下方按钮提交转账信息）" placement="top">
                       <el-button type="info" :disabled="!userInfo.alimot"  @click="submitWithdrawalInfo">提交提现信息</el-button>
                   </el-timeline-item>
                   <el-timeline-item timestamp="人工审核 （三个工作日内处理，请耐心等待）" placement="top"></el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card shadow="hover">
      <Title :title="title[1]"></Title>

      <div class="recharge_record_box">
        <div class="record_tips">
          <img src="@/assets/images/tip.png" />
          <p>平台仅保留近一个月记录明细，有需求请自行导出备份</p>
        </div>

        <!-- 列表区域  border加边框 stripe隔行变色 -->
        <el-table :data="withdrawalRecordInfo.list" border stripe :header-cell-style="{textAlign: 'center'}" :cell-style="{textAlign: 'center'}">
          <el-table-column type="index" label="序号" width="50px"></el-table-column>
          <el-table-column label="提交时间" prop="ctime"></el-table-column>
          <el-table-column label="支付宝账号" prop="alinumber"></el-table-column>
          <el-table-column label="收款人姓名" prop="realname"></el-table-column>
          <el-table-column label="提现金额" prop="money"></el-table-column>
          <el-table-column label="余额" prop="balance"></el-table-column>
          <el-table-column label="打款状态" prop="state">
            <template v-slot:default="scope">
              <el-tag type="success" v-if="scope.row.state==1">提现成功</el-tag>
              <el-tag type="warning" v-else-if="scope.row.state==2">审核中</el-tag>
              <el-tag type="danger" v-else>审核失败</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remarks"></el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination
          :current-page="queryInfo.pagenum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="withdrawalRecordInfo.page_sizes"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="withdrawalRecordInfo.total"
        ></el-pagination>
      </div>
    </el-card>


    <!--绑定支付宝对话框 -->
    <el-dialog title="支付宝账号绑定" :visible.sync="bindingAlipayDialogVisible" width="50%" :close-on-press-escape="false" :close-on-click-modal="false" :before-close="bindingAlipayDialogClosed">
      <!--内容主体区域-->
      <el-form :model="bindingAlipayForm" :rules="userWithdrawalInfoRules" status-icon label-width="150px" ref="bindingAlipayFormRef">
             <el-form-item label="真实姓名：" prop="userName">
                    <el-input  placeholder="请输入您的姓名" v-model="bindingAlipayForm.userName" class="ys"></el-input>
             </el-form-item>
             <el-form-item label="身份证号码：" prop="identityCard">
                    <el-input  placeholder="请输入您的身份证号码" v-model="bindingAlipayForm.identityCard" class="ys"></el-input>
             </el-form-item>
             <el-form-item label="支付宝账号：" prop="alipayAccount">
                    <el-input  placeholder="请输入您的支付宝账号" v-model="bindingAlipayForm.alipayAccount" class="ys"></el-input>
             </el-form-item>
             <el-form-item label="支付宝绑定手机号：" prop="phoneNumber">
                    <el-input  placeholder="请输入您支付宝绑定的手机号" v-model="bindingAlipayForm.phoneNumber" class="ys"></el-input>
             </el-form-item>
             <el-form-item label="获取验证码：" prop="code">
                    <el-input placeholder="请输入验证码" v-model.number="bindingAlipayForm.code" class="ys">
                       <el-button slot="append" :class="yzmStatus ? 'clickcodebtn' : 'codebtn'" :disabled="yzmStatus" @click="getVerificationCode">{{btntext}}</el-button>
                    </el-input>
              </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
         <el-button type="primary" class="haowu hw-queren" @click="submitBindingAlipay"> 确定提交</el-button>
        <el-button @click="bindingAlipayDialogClosed"  type="danger" class="haowu hw-quxiao"> 取消退出</el-button>
      </span>
    </el-dialog>


     <!--提交提现信息对话框 -->
    <el-dialog title="支付宝账号提现" :visible.sync="withdrawalDialogVisible" width="50%" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="withdrawalDialogClosed" >
      <!--内容主体区域-->
      <el-form :model="bindingAlipayForm" :rules="userWithdrawalInfoRules" status-icon label-width="140px" ref="withdrawalFormRef">
             <el-form-item label="提现金额：" prop="withdrawalAmount">
                    <el-input  placeholder="请输入提现金额" v-model.number="bindingAlipayForm.withdrawalAmount" class="ys"></el-input>
             </el-form-item>
             <el-form-item label="手机号码：">
                    <el-input  v-model="userInfo.alimot" class="ys" :disabled="true"></el-input>
             </el-form-item>
            <el-form-item label="获取验证码：" prop="code">
                    <el-input placeholder="请输入验证码" v-model.number="bindingAlipayForm.code" class="ys">
                        <el-button slot="append" :class="yzmStatus ? 'clickcodebtn' : 'codebtn'" :disabled="yzmStatus" @click="getTXVerificationCode">{{btntext}}</el-button>
                    </el-input>
             </el-form-item>
             <el-form-item label="可提现金额：">
                    <span>￥{{userInfo.money}}</span>
             </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
         <el-button type="primary" class="haowu hw-queren" @click="withdrawal"> 确定提交</el-button>
        <el-button @click="withdrawalDialogClosed"  type="danger" class="haowu hw-quxiao"> 取消退出</el-button>
      </span>

    </el-dialog>

  </div>
</template>

<script>
import { mapState,mapGetters } from "vuex";
import { allSpaces,identityCard,checkMobile,isNumber } from "@/utils/validate";
export default {
  name: "recharge",
  data() {
    return {
      title: ["提现", "提现记录"],
      //面包屑数据
      breadcrumbData: [
        {
          id: 1,
          title: "好物电商",
          path: "",
          isClick: false
        },
        {
          id: 2,
          title: "余额管理",
          path: "",
          isClick: false
        },
        {
          id: 3,
          title: "提现",
          path: "withdrawal",
          isClick: true
        }
      ],
      //控制时间线正序还是倒序，false为正序
      reverse: false,
      //添加绑定支付宝的表单数据
      bindingAlipayForm: {
          userName: "",//用户姓名
          identityCard:"",//身份证号码
          alipayAccount:"",//支付宝账号
          phoneNumber:"",//支付宝绑定的手机号
          code:"", //验证码
          withdrawalAmount:"",//提现金额
      },
      //表单的验证规则对象
      userWithdrawalInfoRules:{
           userName: [
             { required: true, message: "请输入您的姓名", trigger: "blur" },
             { validator: allSpaces, trigger: "blur" }
           ],
           identityCard: [
             { required: true, message: "请输入您的身份证号码", trigger: "blur" },
             { validator: identityCard, trigger: "blur" }
           ],
           alipayAccount: [
             { required: true, message: "请输入您的支付宝账号", trigger: "blur" },
             { validator: allSpaces, trigger: "blur" }
           ],
           phoneNumber: [
             { required: true, message: "请输入您的支付宝绑定的手机号", trigger: "blur" },
             { validator: checkMobile, trigger: "blur" }
           ],
           code: [
             { required: true, message: "请输入验证码", trigger: "blur" },
             { validator: allSpaces, trigger: "blur" },
             { validator: isNumber, trigger: "blur" }
           ],
           withdrawalAmount: [
             { required: true, message: "请输入提现的金额", trigger: "blur" },
             { validator: allSpaces, trigger: "blur" },
             { validator: isNumber, trigger: "blur" }
           ]
      },
      //验证码状态
      yzmStatus:false,
      //按钮文字
      btntext:'发送验证码',
      //倒计时
      time:60,
      //获取充值记录列表的参数对象
      queryInfo: {
        // 当前的页数
        pagenum: 1,
        // 当前每一页显示多少条数据
        pagesize: 10
      },
      //控制绑定支付宝对话框的显示与隐藏
      bindingAlipayDialogVisible:false,
      //控制提交提现信息对话框的显示与隐藏
      withdrawalDialogVisible:false
    };
  },
  computed: {
       ...mapState({
           withdrawalRecordInfo:state => state.balance.withdrawalRecordInfo
       }),
      //  ...mapGetters(['userInfo'])
      
      //获取用户信息
      userInfo(){
         return this.withdrawalRecordInfo.user||{}
      }
  },
  methods: {
    //获取提现记录
    getWithdrawalRecord(){
        this.$store.dispatch('getWithdrawalRecord',{num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})
    },

    //添加绑定支付宝账户
    addBindingAlipay(){
        this.bindingAlipayDialogVisible = true
    },
    //提交提现信息
    submitWithdrawalInfo(){
        this.withdrawalDialogVisible = true
    },

    //获取绑定支付宝验证码
    async getVerificationCode(){
            this.$refs.bindingAlipayFormRef.validateField('phoneNumber',async error => {
                    if(!error) {
                            this.time=60;
                            this.timer();
                            try{
                                  const { phoneNumber } = this.bindingAlipayForm
                                  await this.$store.dispatch('getVerificationCode',{mot:phoneNumber})
                            }catch(error){
                                   console.log(error.message)
                            }  
                    }else{
                            return false;
                    }
            });
    },

    //提交绑定支付宝账号
    submitBindingAlipay(){
         this.$refs.bindingAlipayFormRef.validate(async valid => {
             if(!valid) return
             try{
                 const { bindingAlipayForm } = this
                 await this.$store.dispatch('submitBindingAlipay',{realname:bindingAlipayForm.userName,idcard:bindingAlipayForm.identityCard,alinumber:bindingAlipayForm.alipayAccount,alimot:bindingAlipayForm.phoneNumber,yzm:bindingAlipayForm.code})
                 this.$message.success('提交成功,工作人员会在三个工作日之内审核！')
                 this.bindingAlipayDialogVisible = false
                 this.$refs.bindingAlipayFormRef.resetFields();  
                 this.bindingAlipayForm.userName = ""
                 this.bindingAlipayForm.identityCard = ""
                 this.bindingAlipayForm.alipayAccount = ""
                 this.bindingAlipayForm.code = ""
                 this.bindingAlipayForm.userName = ""
                 this.getWithdrawalRecord()
            }catch(error){
                 this.$message.error(error.message)
             }
        })
    },

    //获取提现验证码
    async getTXVerificationCode(){
             this.time=60;
             this.timer();
             try{
                  await this.$store.dispatch('getTXVerificationCode',{mot:this.userInfo.alimot})
             }catch(error){
                  console.log(error.message)
             }  
    },

    //提现
    async withdrawal(){
        this.$refs.withdrawalFormRef.validate(async valid => {
             if(!valid) return
             try{
                   const { bindingAlipayForm } = this
                   await this.$store.dispatch('withdrawal',{money:bindingAlipayForm.withdrawalAmount,mot:this.userInfo.alimot,yzm:bindingAlipayForm.code})
                   this.$message.success('提现成功,工作人员会在三个工作日之内审核转账！')
                   this.withdrawalDialogVisible = false
                   this.$refs.withdrawalFormRef.resetFields();
                   this.bindingAlipayForm.withdrawalAmount = ""
                   this.bindingAlipayForm.code = ""
                   this.getWithdrawalRecord()
             }catch(error){
                   this.$message.error(error.message)
             } 
        })
    },

    //倒计时
    timer(){
        if(this.time>0){
             this.yzmStatus = true;
             this.time--;
             this.btntext=this.time+"秒后再获取";
             setTimeout(this.timer, 1000);
        }else{
            this.time=0;
            this.btntext="发送验证码";
            this.yzmStatus=false;
        }
    },

    //监听绑定支付宝对话框的关闭事件
    bindingAlipayDialogClosed(){
          this.$confirm('确认关闭？', '提示', {
               distinguishCancelAndClose: true,
               confirmButtonText: '确定',
               cancelButtonText: '取消',
               type: 'warning',
               center: true
         })
          .then(async () => {
              this.bindingAlipayDialogVisible = false
              this.$refs.bindingAlipayFormRef.resetFields();  
          })
          .catch(action => {
          });
    },

    //监听提现对话框的关闭事件
    withdrawalDialogClosed(){
          this.$confirm('确认关闭？', '提示', {
               distinguishCancelAndClose: true,
               confirmButtonText: '确定',
               cancelButtonText: '取消',
               type: 'warning',
               center: true
         })
          .then(async () => {
              this.withdrawalDialogVisible = false
              this.$refs.withdrawalFormRef.resetFields();
          })
          .catch(action => {
          });
    },

    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.queryInfo.pagenum = 1;
      this.getWithdrawalRecord()
    },

    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getWithdrawalRecord()
    }
  },
  created() {
      this.getWithdrawalRecord()
  }
};
</script>

<style lang="less" scoped>
.recharge_box {
  width: 100%;
  height: auto;
  margin-top: 10px;
  .external_box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    .line {
      position: absolute;
      left: 0;
      top: 0;
      width: 12px;
      height: 100%;
      background: rgba(@themeColor, 1);
      opacity: 1;
      border-radius: 12px 0 0 12px;
    }
    .content {
      width: 100%;
      height: auto;
      margin-left: 12px;
      border-top: 1px solid #e4e7ed;
      border-right: 1px solid #e4e7ed;
      border-bottom: 1px solid #e4e7ed;
      border-left: 1px solid rgba(@themeColor, 1);
      padding: 20px 30px;
      .balance {
        font-size: 18px;
        span {
          color: rgba(@themeColor, 1);
          font-weight: 600;
        }
      }
      .timeline_box{
          margin-top:20px;
      }
    }
  }
}

.recharge_record_box {
  width: 100%;
  height: auto;
  .record_tips {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    margin-bottom:5px;
    img {
      width: 30px;
      height: 15px;
      margin-right: 5px;
    }
    p {
      color: rgb(255, 172, 28);
      font-size: 15px;
    }
  }
}

.ys {
  width: 300px;
}

.tips {
  color: #d40b35;
  font-size: 15px;
}
.el-card {
  margin-bottom: 20px;
}
.el-timeline{
    padding-left:0 !important;
}
.dialog-footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.codebtn{
    background:#409EEF !important;
    color:#ffffff !important;
    border-radius:0 4px 4px 0 !important;
    border:1px solid #409EEF !important;
}

.clickcodebtn{
    background:#f4f4f5 !important;
    color:#909399 !important;
    border-radius:0 4px 4px 0 !important;
}

.add_bindingAlipay{
    border: 1px dashed #409EEF !important;
    background: #ffffff;
    color:#409EEF;
}
.add_bindingAlipay:hover{
    border: 1px solid #409EEF !important;
}
.alipaytext{
    color:#409EEF;
    font-weight:700;
}
.el-card{
      margin: 20px 20px 15px 20px;
}
.el-pagination {
  margin: 20px 0;
}
</style>